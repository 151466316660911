export default function Storage() {
  const indexedDB =
    window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB

  if (indexedDB === undefined) {
    console.warn('Storage: IndexedDB not available.')
    return { init() {}, get() {}, set() {}, clear() {} }
  }

  const name = 'model-data'
  const version = 1

  let database

  return {
    init(callback) {
      const request = indexedDB.open(name, version)
      request.onupgradeneeded = (event) => {
        const db = event.target.result

        // 判断是否存在表
        if (db.objectStoreNames.contains('states') === false) {
          // 创建表
          db.createObjectStore('states')
        }
      }

      request.onsuccess = (event) => {
        database = event.target.result
        console.log('indexedDB success')

        if (callback) callback()
      }

      request.onerror = (event) => {
        console.error('IndexedDB', event)
      }
    },

    get(key, callback) {
      // 新建事务，对 states 表，进行读写（readwrite）操作
      const transaction = database.transaction(['states'], 'readwrite')
      const objectStore = transaction.objectStore('states')
      // 读取主键值为[key]的数据
      const request = objectStore.get(key)
      request.onsuccess = (event) => {
        callback(event.target.result)
      }
    },

    set(key, data) {
      console.log(key, data)
      const start = performance.now()

      const transaction = database.transaction(['states'], 'readwrite')
      const objectStore = transaction.objectStore('states')
      const request = objectStore.put(data, key)
      request.onsuccess = () => {
        console.log(
          `[${/\d\d:\d\d:\d\d/.exec(new Date())[0]}]`,
          `Saved state to IndexedDB. ${(performance.now() - start).toFixed(2)}ms`
        )
      }
    },

    clear() {
      if (database === undefined) return

      const transaction = database.transaction(['states'], 'readwrite')
      const objectStore = transaction.objectStore('states')
      const request = objectStore.clear()
      request.onsuccess = () => {
        console.log(`[${/\d\d:\d\d:\d\d/.exec(new Date())[0]}]`, 'Cleared IndexedDB.')
      }
    }
  }
}
