<template>
  <div class="header-content _flex _flex-justify__between _flex-align__center">
    <img :src="logo" alt="機電工程署 EMSD" class="header-content__logo" />
    <div class="header-content__tools _flex _flex-justify__between">
      <!-- 时间 -->
      <span class="header-content__tools__date _font-weight__600 _flex _flex-align__center">
        <i class="el-icon-date _font-size__heading3" />
        &emsp;
        <span v-html="dateRender"></span>
      </span>
      <!-- 通知 -->
      <div class="header-content__tools__notice">
        <Notifications></Notifications>
      </div>
      <!-- 用户 -->
      <div class="header-content__tools__user">
        <UserInfo />
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/logo1.png'
import UserInfo from './user-info'
import Notifications from './notifications'

let timeOut = null
export default {
  components: {
    UserInfo,
    Notifications
  },
  data() {
    return {
      logo,
      date: new Date()
    }
  },
  mounted() {
    if (timeOut) clearInterval(timeOut)
    timeOut = setInterval(() => {
      this.date = new Date()
    }, 1000)
  },
  computed: {
    dateRender() {
      const date = [...this.date.toString().matchAll(/\w+\s(\w+)\s(\d+)\s(\d+)\s([\d:]+)/gi)]
      return `TODAY ${date[0][4]}&emsp;${date[0][2]}&nbsp;${date[0][1]}&nbsp;${date[0][3]}`
    }
  },
  destroyed() {
    if (timeOut) clearInterval(timeOut)
  }
}
</script>

<style>
.popover-padding0 {
  padding: 0 !important;
}
</style>
<style lang="scss" scoped>
.header-content {
  height: var(--header-height);
  padding: 0 15px;
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow: 0px 4px 4px rgba(130, 130, 130, 0.15);
  position: relative;
  z-index: 6;

  &__logo {
    width: 120px;
  }

  &__tools {
    // width: 400px;
    height: fit-content;

    &__date {
      font-size: 12px;
      > i {
        font-weight: 600;
      }
    }

    &__notice {
      margin: 0 15px;
      ::v-deep i::after {
        content: '';
        opacity: 0;
        transition: all 0.4s;
      }
      ::v-deep .active {
        position: relative;

        &::after {
          content: attr(data-num);
          opacity: 1;
          position: absolute;
          right: -8px;
          top: -10px;
          min-width: 20px;
          height: 20px;
          line-height: 20px;
          border-radius: 20px;
          color: var(--color-white);
          background: var(--color-primary);
          font-size: 12px;
          text-align: center;
          transform: scale(0.9);
        }
      }
    }

    &__user {
      & ::v-deep .el-popover__reference-wrapper {
        font-size: 12px;
        padding-right: 10px;
      }

      ::v-deep &__text {
        .arrow-up {
          animation: ArrowUp 0.2s forwards;
        }
        .arrow-down {
          animation: ArrowDown 0.2s forwards;
        }
      }
    }
  }
}
@keyframes ArrowUp {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes ArrowDown {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>
