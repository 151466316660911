<template>
  <div v-if="!isComparison">
    <el-button type="info" @click="handleClick('export')">Export 3D</el-button>
    <el-button
      v-if="$store.state.user.currentTowerCode === 'KAM73'"
      type="primary"
      @click="handleClick('comparison')"
    >
      Model Comparison
    </el-button>
  </div>

  <div v-else>
    <el-button plain type="primary" @click="handleClick('close')">Close Comparison</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isComparison: false
    }
  },
  methods: {
    handleClick(e) {
      switch (e) {
        case 'comparison':
          this.isComparison = true
          this.$bus.$emit('model-change', true)
          break
        case 'close':
          this.isComparison = false
          this.$bus.$emit('model-change', false)
          break
        case 'export':
          this.$bus.$emit('export-model')
          break
        default:
      }
    }
  }
}
</script>

<style scoped></style>
