const app = {
  state: {
    reportFilePath: '', // 保存报告文件路径
    towerModeUrl: ''  // 保存塔模型路径
  },

  mutations: {
    SET_REPORT_FILE_PATH(state, value) {
      state.reportFilePath = value
    },
    SET_TOWER_MODE_URL(state, value) {
      state.towerModeUrl = value
    },
  },

  actions: {}
}

export default app
