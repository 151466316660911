<template>
  <el-popover
    placement="bottom"
    width="400"
    trigger="click"
    popper-class="popover-padding0"
    style="padding: 0"
    v-model="visible"
  >
    <div class="user-info _font-weight__600 info-color">
      <div class="user-info__header _flex _flex-justify__between">
        <span class="_font-weight__600">My Profile</span>
        <i class="el-icon-close _pointer" @click="visible = false"></i>
      </div>

      <div class="user-info__detail">
        <div class="user-info__detail__item">
          <span class="_font-weight__400">Name</span>
          <div>{{ userInfo.nickName }}</div>
        </div>

        <div class="user-info__detail__item">
          <span class="_font-weight__400">Email</span>
          <div class="_flex _flex-justify__between">
            <span>{{ userInfo.email }}</span>
            <span
              class="primary-color _pointer"
              @click="
                $router.push({
                  path: '/login',
                  query: {
                    type: update_type
                  }
                })
              "
              >Change Password</span
            >
          </div>
        </div>

        <div class="user-info__detail__item">
          <span class="_font-weight__400">Company Name</span>
          <div>
            <span>{{ userInfo.companyName }}</span>
          </div>
        </div>

        <div class="operate _flex _flex-align__center _flex-justify__between">
          <span class="_pointer" @click="handleSignOut">Sign Out</span>
          <el-button
            type="primary"
            plain
            @click="
              visible = false
              $router.push({
                path: 'edit-profile'
              })
            "
            >Edit Profile</el-button
          >
        </div>
      </div>
    </div>
    <span slot="reference" class="header-content__tools__user__text _pointer _font-weight__600">
      {{ userInfo.nickName }}
      <i :class="['el-icon-caret-top', visible ? 'arrow-down' : 'arrow-up']"></i>
    </span>
  </el-popover>
</template>

<script>
import { mapState } from 'vuex'
import { TYPE_ENUM } from '../../login/components/index'

export default {
  data() {
    return {
      visible: false,
      update_type: TYPE_ENUM.UPDATE_PWD
    }
  },
  computed: mapState({
    userInfo: (state) => state.user.userInfo
  }),
  methods: {
    handleSignOut() {
      this.$store.dispatch('logout').then(() => {
        this.$router.replace('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  &__header {
    padding: 12px 20px;
    border-bottom: 1px solid var(--color-info-light-5);
    font-size: 18px;
  }

  &__detail {
    padding: 20px;

    &__item {
      margin-bottom: 10px;
      > span {
        display: inline-block;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
