// eslint-disable-next-line import/no-cycle
import { optionsFun } from '@/utils'

export default {
  // 没有数据显示时使用此代替
  EMPTY_TEXT: '--',

  // 动态塔ID
  code: 'KAM73',

  // 通用常量合集
  constData: {
    severity: optionsFun({
      Critical: 'Critical',
      Moderate: 'Moderate',
      Minor: 'Minor'
    })
  },

  TRIGGER_TYPE: {
    ROUTER: 'router', // 路由触发
    CLICK: 'click', // 手动点击
    EVENT: 'event' // 事件触发
  },

  oldTowers: ['KAM72', 'KAM73', 'KAM74', 'KAM75', 'KAM76', 'KAM77', 'KAM78', 'KAM79', 'KAM80', 'KAM92'],
}
