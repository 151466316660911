<template>
  <div v-loading="loading" class="empty-image-box">
    <img style="margin-bottom: 20px; width: 100px" :src="notData" fit="cover" />
    <span>No Data</span>
  </div>
</template>

<script>
import notData from '@/assets/not-data.png'

export default {
  props: {
    loading: Boolean
  },
  data() {
    return {
      notData
    }
  },
  methods: {},
  components: {}
}
</script>

<style scoped lang="scss">
.empty-image-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  span {
    display: block;
    width: 100%;
    text-align: center;
    color: #ccc;
  }
}
</style>
