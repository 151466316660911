// import xlsx from 'xlsx'
// eslint-disable-next-line import/no-cycle
import common from '@/common'

/**
 * 转换options
 * @param {object} dictData {0: '否', 1: '是'}
 * @example
 * optionsFun({0: '否', 1: '是'}, 'label', 'code') // [{ label: '否', code: 0 }]
 */
export const optionsFun = (dictData, keyName = 'dictLabel', valName = 'dictValue') => {
  if (Array.isArray(dictData)) {
    return dictData.map((item) => ({ [keyName]: item, [valName]: item }))
  }
  return Object.keys(dictData).map((item) => ({ [keyName]: dictData[item], [valName]: item }))
}

/**
 * 读取字典值
 * @param {string} key 数据字典对象的key
 * @param {string} val 相对应的dictValue值
 * @param {object} dictData 数据源 {[参数 key]: [{dictValue: 0, dictLabel: '否'}]}
 * @example
 * dictReadData('a', 1, {a: [{dictLabel: '是', dictValue: 1}]}) // 是
 * */
export const dictReadData = (key, val, dictData) => {
  const data = dictData[key]
  if (!key || !data || !val || val === common.EMPTY_TEXT) return common.EMPTY_TEXT
  const cur = data.find((item) => item.dictValue === val)
  return cur ? cur.dictLabel : common.EMPTY_TEXT
}

export function exportExcel() {
  // const resultWs = xlsx.utils.json_to_sheet(data)
  // const wb = xlsx.utils.book_new()
  // xlsx.utils.book_append_sheet(wb, resultWs, '-')
  // xlsx.writeFile(wb, `${tableName}.xlsx`)
}

export function resetForm(parseForm, originalForm) {
  Object.keys(parseForm).map((key) => {
    parseForm[key] = originalForm[key]
    return
  })
}

/**
 * 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
 * @param {string} formName 表单组件 ref 值
 */
export function resetFields(formName) {
  if (formName) {
    this.$refs[formName].resetFields()
  } else {
    Object.keys(this.$refs).some((item) => {
      let bol = false
      if (this.$refs[item].hasOwnProperty.call(this.$refs[item], 'resetFields')) {
        this.$refs[item].resetFields()
        bol = true
      }

      return bol
    })
  }
}

/**
 * 格式化时间
 * @author peng-xiao-shuai
 * @param {string | object} time 可以被 new Date() 解析
 * @param {string} pattern 希望以什么格式展示默认 {y}-{m}-{d} {h}:{i}:{s}
 * @example
 * parseTime(1666663200000, '{y}-{m}-{d}') // 2022-10-25
 * parseTime(1666663200000, 'reg') // 25 Oct 2022
 */
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }

  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    } else if (
      typeof time === 'string' &&
      time.indexOf('+0000') === -1 &&
      time.indexOf('+00:00') === -1
    ) {
      time = time.replace(/-/gm, '/')
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time *= 1000
    }
    date = new Date(time)
  }

  // 正则形式返回 {d} {m(英文形式)} {y}。这里不应该设计太死板
  // TODO 待优化支持传递传递参数动态返回
  if (pattern === 'reg') {
    const strArr = date.toString().match(/\w+\s(\w+)\s(\d+)\s(\d+)/)
    return `${strArr[2]} ${strArr[1]} ${strArr[3]}`
  }

  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    w: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|w|f)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'w') return ['日', '一', '二', '三', '四', '五', '六'][value]

    // 时间段 AM or PM
    if (key === 'f') return formatObj.h > 12 ? 'PM' : 'AM'

    if (/{f}/.test(format) && key === 'h' && value > 12) return value - 12

    if (result.length > 0 && value < 10) value = `0${value}`

    return value || 0
  })
  return timeStr
}

export function formatTime(time, option) {
  const d = Number(new Date(time))
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return 'Just now'
  }
  if (diff < 3600) {
    // less 1 hour
    return 'Just now'
    // return `${Math.ceil(diff / 60)}分钟前`
  }
  if (diff < 3600 * 24) {
    return `${Math.ceil(diff / 3600)} hour ago`
  }
  if (diff < 3600 * 24 * 31) {
    return `${Math.ceil(diff / 3600 / 24)} days ago`
  }
  if (diff < 3600 * 24 * 31 * 12) {
    return `${Math.ceil(diff / 3600 / 24 / 31)} month ago`
  }
  if (diff < 3600 * 24 * 31 * 12 * 100) {
    return `${Math.ceil(diff / 3600 / 24 / 31 / 12)} year ago`
  }
  if (option) {
    return parseTime(time, option)
  }
  return `${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`
}
