import request from '@/utils/service'

/**
 * 会员邮箱密码登录
 * @author peng-xiao-shuai
 * @param {string} loginName
 * @param {string} loginPwd
 */
function loginApi(loginName, loginPwd) {
  return request({
    url: '/member/auth/login',
    data: {
      loginName,
      loginPwd
    }
  })
}
export const login = loginApi
export const loginByBusiness = loginApi

/**
 * 注册发送验证码邮件
 * @author peng-xiao-shuai
 * @param {string} email
 */
export function sendMailRegister(email, _url) {
  return request({
    url: `/member/sendMailRegister?email=${email}&url=${_url}`
  })
}

/**
 * 重置密码发送验证码邮件
 * @author peng-xiao-shuai
 * @param {string} email
 */
export function sendMailResetPassword(email, _url) {
  return request({
    url: `/member/sendMailResetPassword?email=${email}&url=${_url}`
  })
}

/**
 * 会员注册
 * @author peng-xiao-shuai
 * @param {} data
 */
export function AuthRegister(data) {
  return request({
    url: '/member/auth/register',
    data
  })
}

/**
 * 检查邮箱是否注册过
 * @author peng-xiao-shuai
 * @param {string} email
 */
export function checkMailRegistered(email) {
  return request({
    url: `/member/checkMailRegistered?email=${email}`
  })
}

/**
 * 重置密码
 * @author peng-xiao-shuai
 * @param {{loginName: string, code: string, newPwd: string}} data
 */
export function resetPwd(data) {
  return request({
    url: '/member/auth/resetPwd',
    data
  })
}

/**
 * 修改用户信息
 * @author peng-xiao-shuai
 * @param {{ email: string, nickName: string}} data
 */

export function setUserInfo(data) {
  return request({ url: '/member/update', data })
}

export function getUserInfo() {
  return request({ url: '/member/info' })
}

export function logout() {
  return request({
    url: '/logout',
    data: {}
  })
}

export function changePassword(data) {
  return request({
    url: '/mgr/changePwd',
    data
  })
}
