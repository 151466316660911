import {
  Popover,
  Scrollbar,
  Tag,
  Input,
  DatePicker,
  TimePicker,
  Pagination,
  Table,
  TableColumn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Upload,
  Checkbox,
  Form,
  FormItem,
  Image,
  Button,
  Select,
  Option,
  Loading,
  MessageBox,
  Message,
  Switch,
  Dialog,
  Row,
  Col,
  Radio
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

export default (Vue) => {
  locale.use(lang)
  Vue.use(Loading.directive)
  Vue.prototype.$loading = Loading.service
  Vue.prototype.$loading = Loading.service
  Vue.prototype.$confirm = MessageBox.confirm
  Vue.prototype.$message = Message

  const components = [
    Popover,
    Scrollbar,
    Tag,
    Input,
    DatePicker,
    TimePicker,
    Pagination,
    Table,
    TableColumn,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Checkbox,
    Upload,
    Form,
    FormItem,
    Image,
    Button,
    Select,
    Option,
    Switch,
    Dialog,
    Row,
    Col,
    Radio
  ]
  components.forEach((item) => {
    Vue.component(item.name, item)
  })
}
