import Vue from 'vue'
import Component from '@/components/index'
import mixins from '@/utils/mixins'
import { LayoutSetCss } from '@/common/size'
import removeCache from '@/utils/remove-chache'
import App from './App'
import Cookies from 'js-cookie'

// 全局方法
import router from './router'
import store from './store'
import { resetForm, resetFields } from './utils'

// 全局声明
import UploadImage from './components/upload/image'
import LoadingData from './components/loading-data/index'

// 全局样式
import 'normalize.css'
import './styles/index.scss'

// import './icons'
import './permission'

LayoutSetCss()
// 每次打包清除数据库数据
removeCache()

Vue.prototype.$resetForm = resetForm
Vue.prototype.$resetFields = resetFields
Vue.component('upload-image', UploadImage)
Vue.component('LoadingData', LoadingData)
Vue.use(Component)
Vue.mixin(mixins)

/** 定义动态rem 1920宽度情况下 文字大小 16px。1rem = 16px
 * @author 彭小黑
 * @date
 */
const size = () => {
  const wW = window.innerWidth
  document.getElementsByTagName('html')[0].style.fontSize = `${wW / 19.2}px`
  // eslint-disable-next-line no-underscore-dangle
  window._fontSize = wW / 19.2
}
size()
window.onresize = size

Vue.config.productionTip = false

if (Cookies.get('tower-token') && localStorage.getItem('currentTowerInfo')) {
  const currentTowerInfo = JSON.parse(localStorage.getItem('currentTowerInfo'))
  store.commit('SET_TOWER_CODE', currentTowerInfo)
} else {
  localStorage.removeItem('currentTowerInfo')
}

new Vue({
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this // 安装全局事件总线，$bus就是当前应用的vm
  },
  render: (h) => h(App)
}).$mount('#app')
