import Cookies from 'js-cookie'
import common from '@/common'
import { login, loginByBusiness, getUserInfo } from '@/api/auth'

const user = {
  state: {
    userInfo: {},
    token: '',

    currentTowerCode: '',
    currentTowerDate: '',
    currentTowerType: ''
  },

  mutations: {
    SET_USERINFO: (state, memberDetail) => {
      state.userInfo = memberDetail
    },
    SET_TOWER_CODE: (state, val) => {
      state.currentTowerCode = val.code
      state.currentTowerType = [1, 2].includes(val.catagories) ? 'isDynamic' : 'isStatic'
      localStorage.setItem('currentTowerInfo', JSON.stringify(val))
    },
    SET_TOWER_DATE: (state, val) => {
      state.currentTowerDate = val
    },
    SET_AUTH: (state, result) => {
      state.token = `Bearer ${result.token}`
      Cookies.set('tower-token', `Bearer ${result.token}`)
    },

    REMOVE_AUTH: () => {
      localStorage.removeItem('userInfo')
      Cookies.remove('tower-token')
    }
  },

  actions: {
    adminLogin({ commit }, userInfo) {
      const username = userInfo.username.trim()

      return new Promise((resolve, reject) => {
        login(username, userInfo.password)
          .then((res) => {
            commit('SET_AUTH')
            resolve(res.result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    businessLogin({ commit }, userInfo) {
      const username = userInfo.username.trim()

      return new Promise((resolve, reject) => {
        loginByBusiness(username, userInfo.password)
          .then((res) => {
            commit('SET_AUTH', res.result)
            commit('SET_USERINFO', res.result.memberDetail)
            resolve(res.result)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    getUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo(state.token)
          .then((res) => {
            commit('SET_USERINFO', res.result)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        // logout().then(() => {
        commit('REMOVE_AUTH')
        resolve()
        // })
      })
    }
  }
}

export default user
