export const MENU = {
  width: 64
}
export const HEADER = {
  height: 60
}
export const NAV = {
  height: 55
}
// eslint-disable-next-line no-var
export const MAIN = {
  // 上下 30
  padding: 30,
  // 左右50
  margin: 50,
  width() {
    return `calc(100vw - ${MENU.width}px - ${this.margin * 2}px)`
  },
  height() {
    return `calc(100vh - ${HEADER.height}px - ${NAV.height}px - ${this.padding * 2}px)`
  },
  // eslint-disable-next-line func-names
  'height-no-nav': function () {
    return `calc(100vh - ${HEADER.height}px - ${this.padding * 2}px)`
  }
}
// MAIN.width()
export const layoutNodeSize = {
  MENU,
  HEADER,
  NAV,
  MAIN
}

/**
 * 动态创建 css 表，挂载配置的常量数据
 * @author peng-xiao-shuai
 */
export const LayoutSetCss = () => {
  const isFun = (that, val) => (typeof val === 'function' ? val.apply(that) : `${val}px`)

  const styleElement = document.createElement('style')
  document.head.appendChild(styleElement)
  const styleObj = {}
  // 循环 layoutNodeSize 属性值
  Object.keys(layoutNodeSize).forEach((prototypeName) => {
    // 循环 layoutNodeSize属性值的属性值
    Object.keys(layoutNodeSize[prototypeName]).forEach((item) => {
      styleObj[`--${prototypeName.toLocaleLowerCase()}-${item}`] = `${isFun(
        layoutNodeSize[prototypeName],
        layoutNodeSize[prototypeName][item]
      )}`
    })
  })
  const style = JSON.stringify(styleObj).replace(/("|,)/g, (key) => {
    if (key === '"') return ''
    if (key === ',') return ';'
    return key
  })

  styleElement.innerHTML = `
    .app-wrapper ${style}
  `
}
