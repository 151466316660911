import Storage from './Storage'

export default function removeCache() {
  const cacheTimestamp = window.localStorage.getItem('time')
  console.log(Number(cacheTimestamp), window.timestamp)
  if (Number(cacheTimestamp) !== window.timestamp) {
    const indexDB = Storage()
    indexDB.init(() => {
      indexDB.clear()
      window.localStorage.setItem('time', window.timestamp)
    })
  }
}
