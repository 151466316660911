<template>
  <div class="menu-content">
    <div
      v-for="item in mainRouter"
      :key="item.path"
      @click="handleClickMenu(item)"
      :class="[
        'menu-content__item _flex _flex-center _pointer',
        { active: $route.meta.parentPath === item.meta.parentPath },
        { disabled: !disabledMenu(item.children[0].meta) }
      ]"
    >
      <!-- 这里直接取值 0 因为路由设计时子集长度只有 1, 写两个标签是为了动画效果 -->
      <div class="img-box _flex _flex-center">
        <component :is="item.meta.icon" />
      </div>

      <div class="menu-content__item__label">
        {{ capitalizeFirstCharacter(item.children[0].meta.label) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mainRouterMap } from '@/router'
import Dashboard from '@/icons/svg/dashboard.svg'
import { mapState } from 'vuex'

export default {
  components: {
    Dashboard
  },
  data() {
    return {
      mainRouter: []
    }
  },
  computed: mapState({
    userInfo: (state) => {
      return state.user.userInfo
    }
  }),
  watch: {
    userInfo: {
      handler(newValue) {
        if (newValue.id) {
          this.filterMenu()
        }
      },
      immediate: true
    }
  },

  methods: {
    filterMenu() {
      const mainRouter = mainRouterMap.filter(item => !['analysis'].includes(item.meta.name))
      if (this.userInfo.authorize) {
        this.mainRouter = mainRouter
      } else {
        this.mainRouter = mainRouter.filter(item => item.meta.name !== 'tower-manage')
      }
    },
    capitalizeFirstCharacter(str) {
      return str.replace(/^\w/, (match) => match.toUpperCase())
    },
    disabledMenu(item) {
      // return !item.towerCode || item.towerCode === this.$store.state.user.currentTowerCode
      if (!this.$store.state.user.currentTowerCode && item.label !== 'home' && item.label !== 'tower-manage') return false
      else return !item.towerCode || this.$store.state.user.currentTowerType === 'isDynamic'
    },

    handleClickMenu(item) {
      this.$store.commit('SET_TOWER_DATE', '')
      this.disabledMenu(item.children[0].meta) &&
          item.children[0].path !== this.$route.path &&
          this.$router.push(item.children[0].path)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-content {
  width: var(--menu-width);
  height: calc(100vh - var(--header-height));
  background: var(--color-white);
  &__item {
    width: var(--menu-width);
    height: var(--menu-width);
    border-left: 4px solid rgba(0, 0, 0, 0);
    transition: all 0.2s;
    position: relative;

    ::v-deep svg {
      // width: 25px;
      // height: 25px;
      path {
        transition: all 0.4s;
      }
    }

    &.active {
      border-color: var(--color-primary);

      ::v-deep svg path {
        fill: var(--color-primary);
      }
    }

    &.disabled {
      cursor: no-drop;
      ::v-deep svg path {
        fill: var(--color-disabled);
      }
    }

    & .img-box {
      // width: 30px;
      // height: 30px;
      position: relative;
      img {
        position: absolute;
        pointer-events: none;
        transition: all 0.4s;
        width: 24px;
        height: 24px;

        &.opacity0 {
          opacity: 0;
        }
      }
    }

    .img-box:hover + .menu-content__item__label {
      opacity: 1;
    }

    &__label {
      position: absolute;
      pointer-events: none;
      transition: all 0.4s;
      right: -100px;
      opacity: 0;
      top: 50%;
      background: rgb(97, 97, 97, 0.8);
      color: #fff;
      width: 110px;
      padding: 5px 0;
      font-size: 14px;
      border-radius: 4px;
      text-align: center;
      z-index: 99999;
      transform: translateY(-50%);

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -9px;
        top: calc(50% - 5px);
        border: 5px solid transparent;
        border-right-color: rgb(97, 97, 97, 0.8);
      }
    }
  }
}
</style>
