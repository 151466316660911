const app = {
  state: {
    imageWall: {
      url: '',
      visible: false
    },

    videoWall: {
      url: '',
      visible: false
    }
  },

  mutations: {
    SHOW_IMAGE_WALL(state, imageUrl) {
      state.imageWall.visible = true
      state.imageWall.url = imageUrl
    },

    HIDDEN_IMAGE_WALL(state) {
      state.imageWall.visible = false
      state.imageWall.url = ''
    },

    SHOW_VIDEO_WALL(state, videoUrl) {
      state.videoWall.visible = true
      state.videoWall.url = videoUrl
    },

    HIDDEN_VIDEO_WALL(state) {
      state.videoWall.visible = false
      state.videoWall.url = ''
    }
  },

  actions: {}
}

export default app
