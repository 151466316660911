<template>
  <div :class="['image-upload', { 'image-upload--mini': isMini }]">
    <el-upload
      ref="upload"
      name="file"
      drag
      with-credentials
      :limit="limit"
      :action="action"
      :before-upload="beforeUpload"
      :show-file-list="false"
      :data="{ folder }"
      :multiple="multiple"
      :disabled="loading"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-exceed="handleExceed"
    >
      <div class="upload__slot">
        <!-- 小按钮上传 -->
        <div v-if="isMini" class="slot__item--mini">
          <el-button :loading="loading" size="mini"><i class="el-icon-plus"></i></el-button>
        </div>
        <!-- 富文本上传 -->
        <div v-else-if="isEditor" class="slot__item--editor">
          <el-button :loading="loading">上传图片</el-button>
        </div>
        <!-- 普通上传 -->
        <div v-else class="slot__item--normal">
          <i v-if="loading" class="el-icon-loading"></i>
          <div v-else class="normal__msg">
            <i class="el-icon-plus"></i>
            <span class="tips">{{ tipsMap[formatMode] }}</span>
          </div>
        </div>
      </div>
    </el-upload>
    <div v-if="!isEditor" class="files-container">
      <div v-if="multiple" class="container__item--multiple">
        <draggable v-model="copyData" @end="onDragEnd">
          <transition-group class="multiple-container">
            <div
              v-for="(item, index) in copyData"
              :key="item.url || item"
              class="container__item--single"
            >
              <video
                class="item__video"
                v-if="item.type === 'video' || formatMode === 'video'"
                :src="(item.url || item) | filterImage"
                controls
                muted
                @click="$store.commit('SHOW_VIDEO_WALL', item.url || item)"
              ></video>
              <img
                v-else
                class="item__image"
                @click="$store.commit('SHOW_IMAGE_WALL', item.url || item)"
                :src="(item.url || item) | filterImage"
              />
              <i @click="deleteImage(index)" class="el-icon-error" />
            </div>
          </transition-group>
        </draggable>
      </div>
      <div v-else class="container__item--single">
        <video
          class="item__video"
          v-if="formatMode === 'video' && copyData"
          :src="copyData | filterImage"
          controls
          muted
          @click="$store.commit('SHOW_VIDEO_WALL', copyData)"
        ></video>
        <img
          v-else-if="copyData"
          :class="['item__image', { mini: isMini }]"
          @click="$store.commit('SHOW_IMAGE_WALL', copyData)"
          :src="copyData | filterImage"
        />
        <i @click="deleteImage" class="el-icon-error" />
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import request from '@/utils/service'

const maxVideoSize = 70
const maxImageSize = 2

export default {
  components: {
    Draggable
  },
  props: {
    folder: {
      type: String,
      default: 'resources'
    },
    formatMode: {
      type: String,
      default: 'image',
      validator: (val) => ['image', 'video', 'mix'].includes(val)
    },
    isMini: {
      type: Boolean,
      default: false
    },
    isEditor: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 0
    },
    originData: [String, Array]
  },

  data() {
    const imageFormats = ['image/jpeg', 'image/png', 'image/webp']
    const videoFormats = ['video/mp4']
    return {
      loading: false,
      action: `${request.defaults.baseURL}/util/file/upload`,
      imageType: '',
      copyData: '',
      multiple: false,

      formatsMap: {
        image: imageFormats,
        video: videoFormats,
        mix: imageFormats.concat(videoFormats)
      },

      tipsMap: {
        image: '请上传图片',
        video: '请上传视频',
        mix: '支持上传图片和视频'
      }
    }
  },

  watch: {
    originData: {
      handler(data) {
        this.multiple = Array.isArray(data)
        this.copyData = this.multiple ? JSON.parse(JSON.stringify(data)) : data

        const uploadFiles = this.$refs.upload && this.$refs.upload.$data.uploadFiles

        if (uploadFiles && !uploadFiles.length) {
          if (this.multiple && this.copyData.length) {
            this.copyData.forEach(() => {
              uploadFiles.push({})
            })
          } else if (!this.multiple && this.copyData) {
            uploadFiles.push({})
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    handleExceed(files, fileList) {
      this.$message.error(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了
        ${files.length + fileList.length} 个文件`
      )
    },
    beforeUpload(file) {
      this.imageType = file.type.split('/').shift()
      const accessType = this.formatsMap[this.formatMode].indexOf(file.type) !== -1

      let exceedMaxSize = false
      if (!accessType) {
        const allowFormatsText = this.formatsMap[this.formatMode]
          .map((item) => item.split('/')[1])
          .join(',')
        let fileName = ''
        if (this.formatMode === 'mix') {
          fileName = this.imageType === 'video' ? '视频' : '图片'
        } else {
          fileName = this.formatMode === 'video' ? '视频' : '图片'
        }
        this.$message.error(`${fileName}格式只接受${allowFormatsText}`)
      } else {
        const maxSize = this.imageType === 'video' ? maxVideoSize : maxImageSize
        exceedMaxSize = file.size / 1024 / 1024 < maxSize
        if (!exceedMaxSize)
          this.$message.error(
            `${this.imageType === 'video' ? '视频' : '图片'}最大不超过${maxSize}M`
          )
      }
      this.loading = accessType && exceedMaxSize
      return accessType && exceedMaxSize
    },

    handleSuccess(res) {
      this.loading = false
      this.$message.success('上传图片成功')

      const returnRes =
        this.formatMode === 'mix' ? { type: this.imageType, url: res.result } : res.result
      if (this.multiple) {
        this.copyData.push(returnRes)
      } else {
        this.copyData = returnRes
      }

      this.$emit('postImage', returnRes)
    },

    handleError() {
      this.loading = false
    },

    deleteImage(index) {
      const { uploadFiles } = this.$refs.upload.$data
      if (uploadFiles) uploadFiles.pop()
      if (this.multiple) this.copyData.splice(index, 1)
      this.$emit('deleteImageUrl', index)
    },

    onDragEnd() {
      this.$emit('onDragEnd', this.copyData)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-upload {
  display: flex;

  &.image-upload--mini {
    justify-content: center;
    align-items: center;
  }

  .el-upload {
    .upload__slot {
      .slot__item--mini {
        display: flex;
        align-items: center;
      }

      .slot__item--editor {
        .el-button {
          height: 40px;
        }
      }

      .slot__item--normal {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        width: 120px;
        height: 120px;
        border: 1px dashed rgb(156, 156, 156);
        border-radius: 8px;

        .el-icon-loading {
          margin-top: 10px;
          font-size: 20px;
        }

        .normal__msg {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .el-icon-plus {
            margin: 0;
            font-size: 30px;
            color: #999;
          }

          .tips {
            margin-top: 10px;
            padding: 0 10px;
            font-size: 14px;
            line-height: 18px;
            color: #999;
          }
        }

        &:hover {
          border-color: #0075ff;

          .normal__msg .el-icon-plus,
          .normal__msg .tips {
            color: #0075ff;
          }
        }
      }
    }
  }

  .files-container {
    flex: 1;

    .container__item--single {
      position: relative;
      width: fit-content;

      .item__video {
        height: 120px;
        border-radius: 8px;
      }

      .item__image {
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 8px;

        &.mini {
          margin: 10px 0 0 10px;
          width: 75px;
          height: 75px;
          border-radius: 4px;
        }
      }

      .el-icon-error {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px;
        transform: translate(50%, -50%);
        cursor: pointer;
      }

      &:hover .el-icon-error {
        display: block;
      }
    }

    .multiple-container {
      display: flex;
      flex-wrap: wrap;

      .container__item--single {
        margin: 0 15px 15px 0;
      }
    }
  }
}
</style>

<style lang="scss">
.el-upload-dragger {
  overflow: inherit;
  width: 100%;
  height: inherit;
  border: 0;
}
</style>
