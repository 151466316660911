import Cookies from 'js-cookie'
import { Encrypt, Decrypt } from '@/utils/ASE-secret'

export const TYPE_ENUM = {
  FORGET_PWD: 'forget-password',
  SIGN_UP: 'sign-up',
  UPDATE_PWD: 'update-password'
}

export const emailRules = [
  { required: true, message: 'Please enter email', trigger: ['change', 'blur'] },
  {
    trigger: ['change', 'blur'],
    validator: (rule, value, callback) => {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(value)) {
        callback('The mailbox format is incorrect!')
      } else {
        callback()
      }
    }
  }
]

// 渲染函数
export function renderSign(h) {
  return [
    h(
      'span',
      { class: 'info-color' },
      this.isSignIn ? 'Not a member? ' : 'Already have an account? '
    ),
    h(
      'span',
      {
        class: '_font-weight__600 _pointer',
        style: {
          color: '#b70c23'
        },
        on: {
          click: () => {
            this.isSignIn = !this.isSignIn
            this.isShowRuleError = false
            this.$resetFields()
          }
        }
      },
      this.isSignIn ? 'Sign Up' : 'Sign In'
    )
  ]
}

/**
 * 发送验证码前准备
 * @author peng-xiao-shuai
 * @param {{email: string, password: string}} loginForm
 * @param {Object.value(TYPE_ENUM)} type
 */
export const sendToEmail = (loginForm, type = TYPE_ENUM.SIGN_UP) => {
  Cookies.set('password', Encrypt(loginForm.password))
  Cookies.set('email', Encrypt(loginForm.email))
  let params = `type=${type}&password=${loginForm.password}&email=${loginForm.email}`

  if (loginForm.username) {
    Cookies.set('username', Encrypt(loginForm.username))
    params += `&username=${loginForm.username}`
  }

  const url =
    process.env.NODE_ENV === 'production'
      ? `https://pylon.raspect.ai/login?params=${Encrypt(params)}`
      : `http://localhost:8080/login?params=${Encrypt(params)}`

  return url
}

// url地址栏参数转Obj
export const urlToObj = (params) => {
  const paramData = {}
  Decrypt(params.replaceAll(' ', '+'))
    .split('&')
    .forEach((item) => {
      const arr = item.split('=')
      // eslint-disable-next-line prefer-destructuring
      paramData[arr[0]] = arr[1]
    })

  return paramData
}
