<template>
  <el-popover
    placement="bottom"
    width="400"
    trigger="click"
    popper-class="popover-padding0"
    style="padding: 0"
    v-model="show"
  >
    <div class="notification">
      <div class="notification-header _flex _flex-justify__between">
        <span class="_font-weight__600">Notifications</span>
        <i class="el-icon-close _pointer" @click="show = false"></i>
      </div>

      <div v-if="listResult.rows.length">
        <div class="notification-clear _flex">
          <span class="_pointer" @click="handleClearAll">Clear All</span>
        </div>

        <el-scrollbar style="height: calc(100vh - 250px)">
          <div style="padding: 0 15px">
            <div
              v-for="(item, index) in listResult.rows"
              :key="index"
              v-loading="item.loading"
              :class="[
                'notification-list__item _flex _flex-justify__between _flex-align__center _font-weight__600',
                { active: item.status === 0 }
              ]"
              @click="handleUpdateStatus(item, index)"
            >
              <div class="notification-list__item__left">
                <div class="_font-size__sub__headings item-title">
                  {{ item.title }}
                </div>
                <span class="item-desc _font-weight__400">
                  {{ item.content }}
                  <!-- <span class="primary-color">{{ '3°' }}</span> -->
                </span>
              </div>
              <div class="notification-list__item__right _flex">
                <span class="item-time _font-weight__400" v-show="item.createdTime">{{
                  renderTime(item.createdTime)
                }}</span>
                <i class="el-icon-error item-clear _pointer" @click.stop="handleRemove(item)"></i>
              </div>
            </div>
          </div>
        </el-scrollbar>

        <el-pagination
          background
          layout="->, pager"
          :total="Number(listResult.total)"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

      <div
        class="notification-list _font-weight__600"
        style="color: #999; text-align: center"
        v-else
      >
        No Data
      </div>
    </div>
    <i
      slot="reference"
      :class="['el-icon-message-solid _font-size__heading3', { active: Number(listResult.total) }]"
      :data-num="listResult.total"
    />
  </el-popover>
</template>

<script>
import { noticeList, noticeDelete, noticeClearAll, noticeUpdateStatus } from '@/api/home'
import { formatTime } from '@/utils'
import router from '@/router'

export default {
  data() {
    return {
      show: false,
      listResult: {
        rows: [],
        total: 0
      },
      listQuery: {
        pageSize: 10,
        pageNo: 1
      }
    }
  },
  mounted() {
    this.getData()
    router.afterEach((to) => {
      if (to.path !== '/login') {
        this.getData()
      }
    })
  },
  methods: {
    renderTime(val) {
      return formatTime(Number(new Date(val)))
    },
    getData(detail) {
      noticeList(this.listQuery).then((res) => {
        if (detail) {
          detail.loading = false
        }
        res.result.rows.forEach((item) => {
          item.loading = false
        })
        this.listResult = res.result
      })
    },
    handleCurrentChange(e) {
      this.listQuery.pageNo = e
      this.getData()
    },
    handleClearAll() {
      noticeClearAll().then(() => {
        this.listResult.rows.splice(0, this.listResult.rows.length)
        this.listResult.total = 0
      })
    },
    handleUpdateStatus(item, index) {
      if (item.status === 1) return
      this.listResult.rows[index].status = 1

      noticeUpdateStatus(item.id).catch(() => {
        this.listResult.rows[index].status = 0
      })
    },
    handleRemove(item) {
      item.loading = true
      noticeDelete(item.id).then(() => {
        if (this.listResult.rows.length === 1) {
          this.listQuery.pageNo -= 1
        }
        this.getData(item)
        // this.listResult.rows.splice(index, 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  color: var(--color-black);
  background: #f4f4f4;

  &-header {
    padding: 12px 20px;
    border-bottom: 1px solid var(--color-info-light-5);
    font-size: 18px;
  }

  &-list {
    padding: 20px 15px;

    &__item {
      padding: 15px;
      border-radius: 5px;
      transition: background 0.3s;

      &.active {
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 1px;
          top: calc(8px / 2 + 15px);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #1f97f5;
        }
      }

      &:hover {
        background: var(--color-white);
      }

      &:hover .item-clear {
        opacity: 1;
      }

      &__right {
        height: 40px;
      }

      .item-title {
        margin-bottom: 5px;
      }
      .item-desc {
        font-size: 12px;
      }
      .item-time {
        font-size: 12px;
        transform: scale(0.9);
        margin-right: 34px;
      }
      .item-clear {
        opacity: 0;
        font-size: 22px;
        align-self: center;
        transition: all 0.3s;
      }
    }

    &__item + .notification-list__item {
      margin-top: 20px;
    }
  }

  &-clear {
    padding: 20px;
    justify-content: end;

    span {
      border-bottom: 1px solid var(--color-black);
    }
  }
}
</style>
