import common from '@/common'

export const pxConvertRem = (num) => {
  // 100 为基数
  return `${num / 100}rem`
}

export default {
  methods: {
    // px转换rem
    pxConvertRem,
    /**
     * 对象解析
     * @author peng-xiao-shuai
     * @param {object} data 数据
     * @param {string} keyStr key字符串
     * @param {boolean} isResolution 是否需要解析字符串
     * @example
     * resolution({a: {b: {c: {d: 1}}}}, 'a.b.c.d') // 1
     */
    resolution(data, keyStr, isResolution = true) {
      if (!keyStr) return common.EMPTY_TEXT
      if (!Object.keys(data).length) return common.EMPTY_TEXT

      if (!isResolution) {
        return data[keyStr] || common.EMPTY_TEXT
      }
      const keys = keyStr.split('.')
      let rtn = JSON.parse(JSON.stringify(data))
      keys.forEach((key) => {
        rtn = [null, undefined].includes(rtn) ? common.EMPTY_TEXT : rtn[key]
      })

      return [null, undefined].includes(rtn) ? common.EMPTY_TEXT : String(rtn)
    }
  }
}
